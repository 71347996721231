<style lang="less" scoped>
.page__wrapper {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div class="page__wrapper">
    <simple-table
      :table-column="tableColumn"
      :table-data="tableData"
      :table-page-info="tablePageInfo"
      :table-data-count="tableDataTotalCount"
      @page-info-change="handlePageInfoChange"
    >
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            type="primary"
            @click="handleAddClick"
          >
            {{ $t('operation.create') }}
          </el-button>
        </template>
      </simple-form>
      <template
        slot="status"
        slot-scope="scope"
      >
        {{ $getDictLabel({type: 'data_status', value: scope.row.status}) }}
      </template>
      <template
        slot="operation"
        slot-scope="scope"
      >
        <el-button
          circle
          icon="el-icon-search"
          :title="$t('operation.view')"
          @click="handleViewClick(scope.row)"
        />
        <el-button
          v-permission="'system_user:edit'"
          circle
          icon="el-icon-edit"
          :title="$t('operation.edit')"
          @click="handleEditClick(scope.row)"
        />
        <el-button
          v-permission="'system_user:unlock'"
          circle
          icon="el-icon-unlock"
          :title="$t('system_user.unlock')"
          @click="handleUnlockClick(scope.row)"
        />
        <el-button
          v-permission="'system_user:reset'"
          circle
          icon="el-icon-thumb"
          :title="$t('system_user.reset')"
          @click="handleResetClick(scope.row)"
        />
      </template>
    </simple-table>
    <detail-dialog
      :show-flag.sync="isShowDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />
  </div>
</template>
<script>
/** 混入分页信息及数据总数信息 */
import pageMixins from '@/components/base/simple-table/mixin'
import detailDialog from './detail'
const BASEURL = {
  list: '/system/user/list',
  delete: '/system/user/delete',
  unlock: '/system/userExtend/unlockUser',
  reset: '/system/user/resetPassword'
}
export default {
  name: 'SystemUser',
  components: { detailDialog },
  mixins: [pageMixins],
  data () {
    return {
      searchModel: {},
      /** 表格数据 */
      tableData: [],
      rowData: {},
      /** 是否显示详情页 dialog */
      isShowDetailDialog: false,
      /** 是否为查看数据 */
      isViewFlag: false
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'loginName',
          label: this.$t('system_user.loginName'),
          type: 'Input',
          component: { clearable: true }
        },
        {
          prop: 'name',
          label: this.$t('system_user.name'),
          type: 'Input',
          component: { clearable: true }
        },
        {
          slotName: 'tableOperation',
          col: { xs: 12, sm: 16, md: 12, style: { textAlign: 'right' } },
          labelWidth: '0'
        }
      ]
    },
    tableColumn () {
      return [
        {
          prop: 'loginName',
          label: this.$t('system_user.loginName'),
          width: 150
        },
        { prop: 'name', label: this.$t('system_user.name'), width: 120 },
        {
          prop: 'company.name',
          label: this.$t('system_user.companyName'),
          width: 180
        },
        // { prop: 'dept.name', label: this.$t('system_user.deptName'), width: 150 },
        // { prop: 'roleNames', label: this.$t('system_user.roleIdList'), width: 150 },
        // { prop: 'phone', label: this.$t('system_user.phone'), width: 150 },
        // { prop: 'email', label: this.$t('system_user.email'), width: 200 },
        {
          prop: 'status',
          label: this.$t('system_user.status'),
          width: 80,
          slotName: 'status'
        },
        { prop: 'remarks', label: this.$t('system_user.remarks') },

        {
          label: this.$t('field.operation'),
          slotName: 'operation',
          width: 130,
          fixed: 'right'
        }
      ]
    }
  },
  /**
   * 注入到子组件
   */
  provide () {
    return { handleDataSearch: this.handleDataSearch }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign({}, { page: this.tablePageInfo }, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      const loadingFlag = this.$loading({
        fullscreen: false,
        target: this.$el,
        text: this.$t('tip.systemLoading')
      })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
          this.tablePageInfo = {
            pageNo: respData.pageNo,
            pageSize: respData.pageSize
          }
          this.tableDataTotalCount = respData.count
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleAddClick () {
      this.rowData = {}
      this.isViewFlag = false
      this.isShowDetailDialog = true
    },
    handleViewClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isViewFlag = true
      this.isShowDetailDialog = true
    },
    handleEditClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isViewFlag = false
      this.isShowDetailDialog = true
    },
    handleUnlockClick (row) {
      const loadingFlag = this.$loading({
        fullscreen: false,
        target: this.$el,
        text: this.$t('tip.systemLoading')
      })
      this.$axios
        .post(`${BASEURL.unlock}?userName=${row.loginName}`)
        .then(resp => {
          this.$message({
            type: 'success',
            message: this.$t('system_user.unlockSuccess')
          })
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleResetClick (row) {
      const loadingFlag = this.$loading({
        fullscreen: false,
        target: this.$el,
        text: this.$t('tip.systemLoading')
      })
      this.$axios
        .post(BASEURL.reset, row)
        .then(resp => {
          this.$message({
            type: 'success',
            message: this.$t('system_user.resetSuccess')
          })
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    deleteRecord (row) {
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            this.$axios
              .post(BASEURL.delete, { id: row.id })
              .then(resp => {
                this.$message({
                  type: 'success',
                  message: this.$t('tip.deleteSuccess')
                })
                done()
              })
              .finally(() => {
                instance.confirmButtonLoading = false
              })
          } else done()
        }
      }).then(() => {
        this.handleDataSearch()
      })
    }
  }
}
</script>
